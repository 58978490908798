import api from "./api";

export async function postSurvey(body) {
  console.log(body);
  const response = await api.post("/survey", body);

  return response.data;
}

export async function getSurveyData(surveyId, token) {
  const response = await api.get(`/survey/${surveyId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getSurveys({ order, skip, take, year }, token) {
  const response = await api.get(
    `/survey/list?order=${order}&skip=${skip}&take=${take}&year=${year}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function deleteSurvey(surveyId, token) {
  const response = await api.delete(`/survey/${surveyId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
